import { Alert, Stack, Step, StepButton, Stepper, Typography } from "@mui/material";
import React from "react";
import { ImportFieldMappingTable } from "@/components/ImportConfiguration/ImportFieldMappingTable";
import { useDataRepositoryContext } from "@/contexts/DataRepositoryContext";
import { fm } from "@/contexts/IntlContext";
import FileImportConfiguration from "./components/FileImportConfiguration";
import { ReviewStep } from "./components/ReviewStep";
import { FILE_CONFIGURATION_STEP, MAPPING_STEP, NOTIFICATION_STEP, useUploadPageContext } from "./context";
import messages from "./messages";

const PROGRESS_MAP = {
    FILE_CONFIGURATION: 0,
    MAPPING: 1,
    NOTIFICATION: 2,
};
interface Props {
    onClose: () => void;
}
export const UploadDataPage: React.FC<Props> = ({ onClose }) => {
    const {
        uploadState: { fileToUpload, progress, selectedImportConfiguration, uploadResponse, warning },
        dispatch,
    } = useUploadPageContext();

    const { selectedDataRepository } = useDataRepositoryContext();
    const handleBack = () => {
        dispatch({ type: "PROGRESS", progress: FILE_CONFIGURATION_STEP });
    };
    const onImport = () => {
        dispatch({ type: "PROGRESS", progress: NOTIFICATION_STEP });
    };
    return (
        <Stack gap={2}>
            <Typography variant="h5" noWrap>
                {fm(messages.header)}
            </Typography>
            <Stepper activeStep={PROGRESS_MAP[progress]}>
                {[fm(messages.uploadFile), fm(messages.mappingStep), fm(messages.reviewStep)].map((label, index) => (
                    <Step key={label.toString() ?? `step${index}`}>
                        <StepButton color="inherit">{label}</StepButton>
                    </Step>
                ))}
            </Stepper>
            {progress === FILE_CONFIGURATION_STEP && <FileImportConfiguration onClose={onClose} />}
            {progress === MAPPING_STEP && selectedImportConfiguration && uploadResponse && fileToUpload && (
                <ImportFieldMappingTable
                    dataRepository={selectedDataRepository}
                    importConfiguration={selectedImportConfiguration}
                    parseResponse={uploadResponse}
                    file={fileToUpload}
                    onImport={onImport}
                    handleBack={handleBack}
                />
            )}
            {progress === NOTIFICATION_STEP && <ReviewStep />}
            {progress !== NOTIFICATION_STEP && warning && (
                <Alert severity={warning.level === "WARNING" ? "warning" : "error"}>{warning.msg}</Alert>
            )}
        </Stack>
    );
};
