/* eslint-disable @typescript-eslint/no-misused-promises */
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { Stack } from "@mui/material";
import * as Sentry from "@sentry/react";
import React, { useState } from "react";
import { LoadingPage } from "@/components/Loading/LoadingPage";
import { DataRepositoryProvider } from "@/contexts/DataRepositoryContext";
import {
    useGetAllDataRepositoriesQuery,
    useGetAllDataRepositoryCollectionsQuery,
    useGetDataRepositoryLazyQuery,
} from "@/generated/client";
import { OnboardingPage } from "../OnboardingPage";
import DataRepositorySideMenu from "./Components/DataRepositorySideMenu";
import DataRepositoryOverview from "./DataRepositoryOverview";
import { testIdPrefix } from "./testIdPrefix";

const DataRepositoryPage: React.FC = () => {
    const [getDataRepository, { result: dataRepositoryResult, refetch: refetchRepository }] =
        useGetDataRepositoryLazyQuery();
    const [sideMenuOpen, setSideMenuOpen] = useState<boolean>(true);

    const { result: dataRepositoriesResult, refetch: refetchDataRepositories } = useGetAllDataRepositoriesQuery({});
    const { result: dataRepositoryCollectionsResult, refetch: refetchDataRepositoryCollections } =
        useGetAllDataRepositoryCollectionsQuery({});

    return (
        <Stack direction="row">
            <DataRepositorySideMenu
                data-testid={`${testIdPrefix}-repository-side-menu`}
                dataRepositoryCollectionsResult={dataRepositoryCollectionsResult}
                dataRepositoriesResult={dataRepositoriesResult}
                refetchDataRepositories={refetchDataRepositories}
                refetchDataRepositoryCollections={refetchDataRepositoryCollections}
                onChange={(id: string) => {
                    refetchRepository({ input: { id } }).catch((e) =>
                        Sentry.captureException(e, { tags: { app: "data-repositories-app" } })
                    );
                }}
                sideMenuOpen={sideMenuOpen}
                onSideMenuToggle={() => setSideMenuOpen((prev) => !prev)}
            />
            <Stack flexGrow={1}>
                <GraphqlRequestContainer asyncData={dataRepositoriesResult} loading={<LoadingPage />}>
                    {(dataRepositories) => dataRepositories.dataRepositories.length !== 0 ? (
                            <DataRepositoryProvider
                                dataRepositoryResult={dataRepositoryResult}
                                getRepository={(id: string) => {
                                    getDataRepository({ input: { id } }).catch((e) => {
                                        Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
                                    });
                                }}
                                initialDataRepository={dataRepositories.dataRepositories[0]}
                            >
                                <DataRepositoryOverview sideMenuOpen={sideMenuOpen} />
                            </DataRepositoryProvider>
                        ) : (
                            <OnboardingPage />
                        )}
                </GraphqlRequestContainer>
            </Stack>
        </Stack>
    );
};

export default DataRepositoryPage;
