import {
    Dialog,
    DialogContent,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    FormControlLabel,
    DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { fm } from "@/contexts/IntlContext";
import { DataRepository } from "@/generated/client";
import { DeleteByValueForm } from "./deleteByValueForm";
import messages from "./messages";
import { RelativeDateForm } from "./relativeDateForm";

export interface DeleteInDateRangeFilter {
    startDate: Date;
    endDate: Date;
    dateFormat: string;
    dateField: string;
}
interface Props {
    currentRepository: DataRepository;
    open: boolean;
    onClose: () => void;
}

const RELATIVE = "RELATIVE";
const VALUE = "VALUE";

export const DeleteConfigBuilder = ({ currentRepository, open, onClose }: Props) => {
    const [queryType, setQueryType] = useState<string>(RELATIVE);
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{fm(messages.newQuery)}</DialogTitle>
            <DialogContent>
                <Stack gap={1}>
                    <FormLabel id="label-delete-config-radio">{fm(messages.type)}</FormLabel>
                    <RadioGroup
                        aria-labelledby="label-delete-config-radio"
                        row
                        value={queryType}
                        onChange={({ target }) => {
                            setQueryType(target.value);
                        }}
                        name="delete-config-radio-group"
                    >
                        <FormControlLabel value={RELATIVE} control={<Radio />} label={fm(messages.relative)} />
                        <FormControlLabel value={VALUE} control={<Radio />} label={fm(messages.byValue)} />
                    </RadioGroup>
                    {queryType === RELATIVE && (
                        <RelativeDateForm onFinish={onClose} currentRepository={currentRepository} />
                    )}
                    {queryType === VALUE && (
                        <DeleteByValueForm onFinish={onClose} currentRepository={currentRepository} />
                    )}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
