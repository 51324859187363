/* eslint-disable react/jsx-no-useless-fragment */
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import React, { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { LoadingPage } from "@/components/Loading/LoadingPage";
import { DataRepository, GraphqlAsyncData } from "@/generated/client";

type ContextValue = {
    selectedDataRepository: DataRepository;
    resetToInitialDataRepository: () => void;
};

const Context = React.createContext<ContextValue | null>(null);

const DataRepositoryProvider: React.FC<{
    children: React.ReactNode;
    dataRepositoryResult: GraphqlAsyncData<{ dataRepository: DataRepository }>;
    getRepository: (id: string) => void;
    initialDataRepository: DataRepository;
}> = ({ dataRepositoryResult, getRepository, initialDataRepository, children }) => {
    const { repositoryId: paramsRepositoryId } = useParams<{ repositoryId: string }>();

    const selectedDataRepository =
        dataRepositoryResult.type === "success" ? dataRepositoryResult.data.dataRepository : initialDataRepository;

    useEffect(() => {
        if (dataRepositoryResult.type === "not-asked" && initialDataRepository) {
            const id = paramsRepositoryId || initialDataRepository.id;
            getRepository(id);
        }
    }, [dataRepositoryResult.type, initialDataRepository, paramsRepositoryId, getRepository]);

    const state = useMemo(
        () => ({
            selectedDataRepository,
            resetToInitialDataRepository: () => {
                getRepository(selectedDataRepository.id);
            },
        }),
        [selectedDataRepository, getRepository]
    );
    return (
        <Context.Provider value={state}>
            <GraphqlRequestContainer asyncData={dataRepositoryResult} loading={<LoadingPage />}>
                {(_) => <>{children}</>}
            </GraphqlRequestContainer>
        </Context.Provider>
    );
};

const useDataRepositoryContext = () => {
    const ctx = useContext(Context);
    if (!ctx) {
        throw new Error("DataRepositoryContext must be used within a DataRepositoryProvider");
    }
    return ctx;
};

export { DataRepositoryProvider, useDataRepositoryContext };
