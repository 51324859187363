import React, { useCallback, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";

import { Box } from "@mui/material";
import { DataRepository, DataRepositoryRow } from "@/generated/client";
import { RenderCell } from "../RenderCell";

interface Props {
    dataRepository: DataRepository;
    searchTerm?: string;
    rows: DataRepositoryRow[];
    rowCount?: number;
    pageSize: number;
    setPaginationModel: (paginationModel: { page: number; pageSize: number }) => void;
    page: number;
}

const DataGridTable: React.FC<Props> = ({
    dataRepository,
    searchTerm,
    rows,
    pageSize,
    setPaginationModel,
    page,
    rowCount,
}) => {
    const columnsDef = dataRepository.fields
        .filter((field) => !searchTerm || field.name.toLowerCase().includes(searchTerm.toLowerCase()))
        .map((field) => {
            const width = Math.max(field.name.length * 11, 100);
            return {
                minWidth: width,
                renderCell: RenderCell,
                field: field.id,
                headerName: field.name,
                flex: 1,
                sortable: false,
                filterable: false,
                disableColumnFilter: true,
                disableColumnMenu: true,
            };
        });

    const parsedRows = useMemo(() => rows.map<{ data: Record<string, string>; rowId: string }>((row) => {
            try {
                return { data: JSON.parse(row.dataJson), rowId: row.id };
            } catch (error) {
                return { data: {}, rowId: row.id };
            }
        }), [rows]);

    const rowDefs = parsedRows.map((row) => ({
            id: row.rowId,
            ...row.data,
        }));

    const calculateHeight = () => {
        const height = window.innerHeight;
        if (height > 860) return "69vh";
        if (height > 800) return "65vh";
        return "60vh";
    };
    const onPaginationModelChange = useCallback(
        (model: { page: number; pageSize: number }) => {
            setPaginationModel(model);
        },
        [setPaginationModel]
    );
    return (
        /* this is how MUI adds a data grid container in their docs */
        <Box sx={{ height: calculateHeight(), width: "100%" }}>
            <DataGrid
                columnHeaderHeight={40}
                rowHeight={30}
                rowCount={rowCount}
                rows={rowDefs}
                columnBuffer={10}
                pageSizeOptions={[25, 50, 100]}
                paginationModel={{ page, pageSize }}
                pagination
                onPaginationModelChange={onPaginationModelChange}
                columns={columnsDef}
                paginationMode="server"
            />
        </Box>
    );
};

export { DataGridTable };
