import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { useDataRepositoryContext } from "@/contexts/DataRepositoryContext";
import { fm } from "@/contexts/IntlContext";
import { GraphqlAsyncData } from "@/generated/client";
import messages from "./messages";

interface Props {
    count: GraphqlAsyncData<{
        __typename: "GetDataRepositoryRowCountResponse";
        rowCount: number;
    }>;
}

const RepositoryStats: React.FC<Props> = ({ count }) => {
    const { selectedDataRepository } = useDataRepositoryContext();
    return (
        <Stack justifyContent="flex-start" direction="row" spacing={1}>
            <Typography variant="subtitle1" color="secondary" noWrap>
                {fm(messages.fieldCount, { fieldCount: selectedDataRepository.fields.length })}
            </Typography>
            <GraphqlRequestContainer
                asyncData={count}
                loading={
                    <Typography variant="subtitle1" color="secondary" noWrap>
                        {fm(messages.loading)}
                    </Typography>
                }
            >
                {(rowCountResponse) => (
                    <Typography variant="subtitle1" color="secondary" noWrap>
                        {fm(messages.rowCount, { rowCount: rowCountResponse.rowCount })}
                    </Typography>
                )}
            </GraphqlRequestContainer>
        </Stack>
    );
};

export default RepositoryStats;
