import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, Skeleton, Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DataRepositoryMenu from "@/components/RepositoryMenu";
import { useBasePath } from "@/contexts/BasePathContext";
import { fm } from "@/contexts/IntlContext";
import {
    DataRepository,
    GetAllDataRepositoriesQuery,
    GetAllDataRepositoryCollectionsQuery,
    GraphqlAsyncData,
} from "@/generated/client";
import { Stringish } from "@/utils";
import messages from "../../messages";
import CreateDataRepositoryOrCollectionModal from "../modals/CreateRepositoryOrCollectionModal";
import CreateDataRepositoryCollectionModal from "./CreateCollectionForm";
import CreateDataRepositoryForm from "./CreateDataRepositoryForm";
import CreateMenu from "./CreateMenu";

export const SIDE_MENU_WIDTH = 260;

const ExitRow: React.FC<{
    message: Stringish;
    closer: () => void;
}> = ({ message, closer }) => (
        <DialogTitle>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h1">{message.toString()}</Typography>
                <IconButton onClick={closer}>
                    <CloseIcon />
                </IconButton>
            </Stack>
        </DialogTitle>
    );

interface Props {
    dataRepositoryCollectionsResult: GraphqlAsyncData<
        GetAllDataRepositoryCollectionsQuery["getAllDataRepositoryCollections"]
    >;
    dataRepositoriesResult: GraphqlAsyncData<GetAllDataRepositoriesQuery["getAllDataRepositories"]>;
    refetchDataRepositories: () => void;
    refetchDataRepositoryCollections: () => void;
    onChange: (id: string) => void;
    onSideMenuToggle: () => void;
    sideMenuOpen: boolean;
}

const DataRepositorySideMenu: React.FC<Props> = ({
    dataRepositoryCollectionsResult,
    dataRepositoriesResult,
    refetchDataRepositories,
    refetchDataRepositoryCollections,
    onChange,
    onSideMenuToggle,
    sideMenuOpen,
}) => {
    const base = useBasePath();
    const [isCreateModalOpen, setIsCreateModalOpen] = useState<"COLLECTION" | "REPOSITORY" | "PICK" | false>(false);
    const browserHistory = useHistory();

    const { repositoryId: currentRepositoryId } = useParams<{ repositoryId: string }>();

    const handleCreatedRepository = (dataRepository: DataRepository) => {
        onChange(dataRepository.id);
        browserHistory.push(`${base}${dataRepository.id}/data-preview/`);
        setIsCreateModalOpen(false);
        refetchDataRepositories();
        refetchDataRepositoryCollections();
    };

    const initialRepositoryId =
        dataRepositoriesResult.type === "success" ? dataRepositoriesResult.data.dataRepositories[0]?.id : "";

    if (initialRepositoryId && !currentRepositoryId) browserHistory.push(`${base}${initialRepositoryId}/data-preview/`);
    return (
        <Stack gap={1} paddingTop={1} justifyContent="space-between">
            <Stack justifyContent="flex-start" alignContent="flex-start">
                <GraphqlRequestContainer
                    asyncData={dataRepositoryCollectionsResult}
                    loading={<Skeleton variant="rectangular" width={SIDE_MENU_WIDTH} height="60vh" />}
                >
                    {(collectionResponse) => (
                        <GraphqlRequestContainer
                            asyncData={dataRepositoriesResult}
                            loading={<Skeleton variant="rectangular" width={SIDE_MENU_WIDTH} height="60vh" />}
                        >
                            {(repositoriesResponse) => (
                                <>
                                    <DataRepositoryMenu
                                        dataRepositoryCollections={collectionResponse.dataRepositoryCollections}
                                        dataRepositories={repositoriesResponse.dataRepositories}
                                        onClick={onChange}
                                        sideMenuOpen={sideMenuOpen}
                                        onMenuToggle={onSideMenuToggle}
                                        CreateButton={
                                            <CreateMenu
                                                dataRepositoryCollections={collectionResponse.dataRepositoryCollections}
                                                dataRepositories={repositoriesResponse.dataRepositories}
                                                refetchDataRepositories={refetchDataRepositories}
                                                refetchDataRepositoryCollections={refetchDataRepositoryCollections}
                                                onCreate={onChange}
                                            />
                                        }
                                    />

                                    <Dialog open={!!isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)}>
                                        <DialogContent>
                                            {isCreateModalOpen === "COLLECTION" && (
                                                <CreateDataRepositoryCollectionModal
                                                    repositories={repositoriesResponse.dataRepositories}
                                                />
                                            )}
                                            {isCreateModalOpen === "REPOSITORY" && (
                                                <>
                                                    <ExitRow
                                                        message={fm(messages.createRepository)}
                                                        closer={() => setIsCreateModalOpen(false)}
                                                    />
                                                    <CreateDataRepositoryForm
                                                        dataRepositoryCollections={
                                                            collectionResponse.dataRepositoryCollections
                                                        }
                                                        onCreate={handleCreatedRepository}
                                                    />
                                                </>
                                            )}
                                            {isCreateModalOpen === "PICK" && (
                                                <CreateDataRepositoryOrCollectionModal onPick={setIsCreateModalOpen} />
                                            )}
                                        </DialogContent>
                                    </Dialog>
                                </>
                            )}
                        </GraphqlRequestContainer>
                    )}
                </GraphqlRequestContainer>
            </Stack>
        </Stack>
    );
};

export default DataRepositorySideMenu;
