import { fm } from "@/contexts/IntlContext";
import messages from "./ImportLogTableRow/messages";

export const getUploadInfo = (
    importedRows: number,
    updatedRows: number,
    ignoredEmpty: number,
    ignoredMissing: number,
    alreadyExisting: number
) => ({
        imported: importedRows !== -1 ? importedRows : fm(messages.missing).toString(),
        updated: updatedRows !== -1 ? updatedRows : fm(messages.missing).toString(),
        invalid:
            ignoredEmpty !== -1 && ignoredMissing !== -1
                ? ignoredMissing + ignoredEmpty
                : fm(messages.missing).toString(),
        ignored: alreadyExisting !== -1 ? alreadyExisting : fm(messages.missing).toString(),
    });
