import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { track } from "@ignite-analytics/track";
import { Close } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Alert,
    Button,
    Checkbox,
    Grid,
    IconButton,
    Paper,
    Snackbar,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    tableRowClasses,
    Tooltip,
    Typography,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { LoadingTable } from "@/components/Loading/LoadingTable";
import { NotificationModal } from "@/components/NotificationModal";
import DeletePrompt from "@/components/Prompt/DeletePrompt";
import { useDataManagementProcessEventListener } from "@/contexts/DataManagementProcessContext";
import { useDataRepositoryContext } from "@/contexts/DataRepositoryContext";
import { useEntityEventListener } from "@/contexts/EntityEventChangeContext";
import { fm } from "@/contexts/IntlContext";
import { useAllUsers } from "@/entities/users";
import {
    useDeleteImportsMutation,
    useGetPaginatedImportsByDataRepositoryQuery,
    useGetSupplierTableDataSourcesQuery,
} from "@/generated/client";
import { formatGrpcErrorMessage } from "@/helpers";
import { testIdPrefix } from "../../../testIdPrefix";
import { HEADER_MESSAGES } from "./constants";
import ImportLogTableRow from "./ImportLogTableRow/index";
import messages from "./messages";

export const LogTable: React.FC = () => {
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(25);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [allChecked, setAllChecked] = useState(false);
    const [deleteImportsModalOpen, setDeleteImportsModalOpen] = useState(false);
    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const [notificationId, setNotificationId] = useState<number | undefined>();
    const { selectedDataRepository } = useDataRepositoryContext();
    const [selectedForDeletion, setSelectedForDeletion] = useState<string[]>([]);
    const [deleteImports, { result: deleteImportsResult }] = useDeleteImportsMutation();
    const { result: supplierTableSources } = useGetSupplierTableDataSourcesQuery({});
    const { result: importsResult, refetch } = useGetPaginatedImportsByDataRepositoryQuery({
        input: {
            index: page * size,
            range: size,
            request: { dataRepositoryId: selectedDataRepository.id },
        },
    });

    useEffect(() => {
        if (deleteImportsResult.type === "error") {
            setSnackbarOpen(true);
        }
    }, [deleteImportsResult.type]);

    const handleClose = (_event: SyntheticEvent | UIEvent, reason?: string) => {
        if (reason === "clickaway") return;
        setSnackbarOpen(false);
    };
    useDataManagementProcessEventListener(
        "DataRepositoryDataChange",
        function refetchDataWhenUploadIsFinished(process) {
            if (process.type === "PROCESS_FINISHED" && process?.id === selectedDataRepository.id) {
                refetch();
            }
        }
    );

    useEntityEventListener("Import", (_event) => {
        refetch();
    });

    const onCheckCheckBox = (deleteId: string, removeCheckMark: boolean) => {
        const filteredImports = selectedForDeletion.filter((selected) => selected !== deleteId);
        if (!removeCheckMark) {
            setSelectedForDeletion([...filteredImports, deleteId]);
        } else {
            setSelectedForDeletion(filteredImports);
        }
    };

    const onSelectAll = (removeCheckMark: boolean) => {
        if (!removeCheckMark) {
            setSelectedForDeletion([]);
        } else {
            importsResult.type === "success" &&
                setSelectedForDeletion(importsResult.data.imports.map((importObj) => importObj.id));
        }
        setAllChecked(!allChecked);
    };

    const users = useAllUsers();
    const handleDeleteImports = () => {
        setDeleteImportsModalOpen(false);
        setShowNotificationModal(true);
        deleteImports({
            input: {
                dataRepositoryId: selectedDataRepository.id,
                importIDs: selectedForDeletion,
            },
        })
            .then((result) => {
                setNotificationId(Number(result?.data?.deleteImports.notificationId));
                track("Repository import log: Delete Import");
                setSelectedForDeletion([]);
            })
            .catch((e) => {
                Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
            });
    };

    useEffect(
        function updateSelectedImports() {
            refetch();
        },
        [size, refetch]
    );

    useEffect(() => {
        setSelectedForDeletion([]);
    }, [selectedDataRepository]);

    const allowSupplierImportDeletion = useFeatureToggle("allow-supplier-import-deletion", false);

    return (
        <Grid container paddingTop={1}>
            <Grid item justifyContent="flex-start" paddingBottom={1}>
                <GraphqlRequestContainer asyncData={supplierTableSources}>
                    {({ sources }) => {
                        const isSupplierRepo = sources.map((s) => s.id).includes(selectedDataRepository.id);
                        return isSupplierRepo && !allowSupplierImportDeletion ? (
                            <Tooltip title={fm(messages.supplierSourceDeletionDisabled)}>
                                <span>
                                    <Button
                                        startIcon={<DeleteIcon />}
                                        color="error"
                                        variant="outlined"
                                        onClick={() => setDeleteImportsModalOpen(true)}
                                        data-testid={`${testIdPrefix}-importlog-tab-delete-imports-button`}
                                        size="small"
                                        disabled
                                    >
                                        {fm(messages.deleteImport)}
                                    </Button>
                                </span>
                            </Tooltip>
                        ) : (
                            <Button
                                startIcon={<DeleteIcon />}
                                disabled={selectedForDeletion.length === 0}
                                color="error"
                                variant="outlined"
                                onClick={() => setDeleteImportsModalOpen(true)}
                                data-testid={`${testIdPrefix}-importlog-tab-delete-imports-button`}
                                size="small"
                            >
                                {fm(messages.deleteImport)}
                            </Button>
                        );
                    }}
                </GraphqlRequestContainer>
            </Grid>
            <GraphqlRequestContainer
                asyncData={importsResult}
                loading={<LoadingTable numberOfRows={size} numberOfColumns={HEADER_MESSAGES.length + 1} />}
            >
                {(importsResponse) => (
                    <TableContainer component={Paper}>
                        <Table stickyHeader size="small" sx={{ width: "100%" }}>
                            <TableHead>
                                <TableRow
                                    sx={{
                                        [`&.${tableRowClasses.head}`]: {
                                            whiteSpace: "nowrap",
                                        },
                                    }}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            size="small"
                                            style={{ padding: 0 }}
                                            name="deleteAllImports"
                                            checked={allChecked}
                                            onChange={(_) => {
                                                onSelectAll(!allChecked);
                                            }}
                                        />
                                    </TableCell>
                                    {HEADER_MESSAGES.map(({ title, tooltip }) => (
                                        <Tooltip key={title} placement="top-start" arrow={false} title={tooltip}>
                                            <TableCell>
                                                <Typography>{title}</Typography>
                                            </TableCell>
                                        </Tooltip>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {importsResponse.imports.map((importObject) => (
                                    <ImportLogTableRow
                                        key={importObject.id}
                                        checked={!!selectedForDeletion.find((selected) => selected === importObject.id)}
                                        importObject={{
                                            id: importObject.id,
                                            file: importObject.file,
                                            importedAt: importObject.importInfo.importedAt,
                                            importedBy: users.find(
                                                (user) => user.id === importObject.importInfo.importedBy
                                            ),
                                            isBeingDeleted: importObject.isBeingDeleted,
                                            importedRows: importObject.importInfo.importedRows,
                                            updatedRows: importObject.importInfo.updatedRows,
                                            ignoredEmpty: importObject.importInfo.ignoredEmpty,
                                            ignoredMissing: importObject.importInfo.ignoredMissing,
                                            alreadyExisting: importObject.importInfo.alreadyExisting,
                                        }}
                                        onCheckCheckBox={onCheckCheckBox}
                                    />
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                        rowsPerPage={size}
                                        count={importsResponse.totalImports}
                                        page={page}
                                        onPageChange={(_, newPage) => {
                                            setPage(newPage);
                                        }}
                                        onRowsPerPageChange={(event) => {
                                            setSize(parseInt(event.target.value, 10));
                                            setPage(0);
                                        }}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                )}
            </GraphqlRequestContainer>
            <DeletePrompt
                title={fm(messages.deleteImportsHeader)}
                description={fm(messages.deletePromptDescription)}
                onCancel={() => setDeleteImportsModalOpen(false)}
                onDelete={handleDeleteImports}
                isProcessing={deleteImportsResult.type === "loading"}
                open={deleteImportsModalOpen}
            />
            {notificationId && (
                <NotificationModal
                    open={showNotificationModal}
                    onClose={() => {
                        setShowNotificationModal(false);
                        setNotificationId(undefined);
                    }}
                    notificationId={`${notificationId}`}
                    title={fm(messages.deleteImportNotificationTitle)}
                />
            )}
            <Snackbar open={snackbarOpen} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                <Alert variant="filled" severity="error">
                    <Stack direction="row" alignItems="center">
                        <Typography>
                            {deleteImportsResult.type === "error" &&
                                formatGrpcErrorMessage(deleteImportsResult.error.message)}
                        </Typography>
                        <IconButton size="small" aria-label="close" onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Stack>
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default LogTable;
