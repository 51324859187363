import { Add, Delete, Edit, MoreHorizOutlined } from "@mui/icons-material";
import { CardContent, ClickAwayListener, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import React from "react";
import globalMessages from "@/messages";

import { fm } from "@/contexts/IntlContext";
import { DataRepository, DataRepositoryCollection } from "@/generated/client";
import { AddRepositoryToCollection } from "./AddRepostiory";
import { DeleteRepositoryCollection } from "./Delete";
import { RenameRepositoryCollection } from "./Rename";

interface Props {
    collection: DataRepositoryCollection | { name: string };
    repositories: DataRepository[];
    editType: "ADD" | "DELETE" | "EDIT" | undefined;
    setEditType: React.Dispatch<React.SetStateAction<"ADD" | "DELETE" | "EDIT" | undefined>>;
    onUpdate?: () => void;
    onDelete?: () => void;
}

export const EditRepositoryCollection: React.FC<Props> = ({
    editType,
    setEditType,
    onUpdate,
    onDelete,
    collection,
    repositories,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <ClickAwayListener
            onClickAway={() => {
                setEditType(undefined);
            }}
        >
            <>
                <IconButton onClick={handleClick}>
                    <MoreHorizOutlined />
                </IconButton>

                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <CardContent>
                        {editType === undefined && (
                            <>
                                <MenuItem
                                    onClick={() => {
                                        setEditType("ADD");
                                    }}
                                >
                                    <Stack direction="row" gap={1}>
                                        <Add />
                                        {fm(globalMessages.add)}
                                    </Stack>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setEditType("EDIT");
                                    }}
                                >
                                    <Stack direction="row" gap={1}>
                                        <Edit />
                                        {fm(globalMessages.rename)}
                                    </Stack>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setEditType("DELETE");
                                    }}
                                >
                                    <Stack direction="row" gap={1}>
                                        <Delete />
                                        {fm(globalMessages.delete)}
                                    </Stack>
                                </MenuItem>
                            </>
                        )}

                        {editType === "ADD" && (
                            <AddRepositoryToCollection
                                collection={collection}
                                setEditType={setEditType}
                                onUpdate={onUpdate}
                                repositories={repositories}
                            />
                        )}

                        {"id" in collection && editType === "EDIT" && (
                            <RenameRepositoryCollection
                                collection={collection}
                                setEditType={setEditType}
                                onUpdate={onUpdate}
                            />
                        )}
                        {"id" in collection && editType === "DELETE" && (
                            <DeleteRepositoryCollection
                                collection={collection}
                                setEditType={setEditType}
                                onUpdate={onUpdate}
                                onDelete={onDelete}
                            />
                        )}
                    </CardContent>
                </Menu>
            </>
        </ClickAwayListener>
    );
};
