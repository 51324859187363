import { Button, Stack, TextField } from "@mui/material";
import * as Sentry from "@sentry/react";
import React, { useState } from "react";
import { fm } from "@/contexts/IntlContext";
import { DataRepositoryCollection, useUpdateDataRepositoryCollectionMutation } from "@/generated/client";
import globalMessages from "@/messages";

interface Props {
    collection: DataRepositoryCollection | { name: string };
    setEditType: React.Dispatch<React.SetStateAction<"ADD" | "DELETE" | "EDIT" | undefined>>;
    onUpdate?: () => void;
}

export const RenameRepositoryCollection: React.FC<Props> = ({ setEditType, onUpdate, collection }) => {
    const [updateCollection] = useUpdateDataRepositoryCollectionMutation({
        refetchQueries: ["getAllDataRepositoryCollections"],
    });
    const [name, setName] = useState<string>();

    const handleRename = () => {
        if (name === collection.name) return;
        if ("id" in collection) {
            name &&
                updateCollection({ input: { id: collection.id, name } })
                    .then(() => {
                        onUpdate?.();
                        setEditType(undefined);
                    })
                    .catch((e) => {
                        Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
                    });
        }
    };

    return (
        <Stack gap={1}>
            <TextField
                placeholder={collection.name}
                value={name}
                label={fm(globalMessages.rename)}
                onChange={(e) => {
                    setName(e.target.value);
                }}
            />

            <Stack direction="row" gap={1} justifyContent="space-between">
                <Button size="small" color="secondary" variant="outlined" onClick={() => setEditType(undefined)}>
                    {fm(globalMessages.cancel)}
                </Button>

                <Button size="small" color="success" onClick={handleRename}>
                    {fm(globalMessages.rename)}
                </Button>
            </Stack>
        </Stack>
    );
};
