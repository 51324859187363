import React from "react";
import { DataRepository, DataRepositoryRow } from "@/generated/client";
import { DataGridTable } from "./DataGrid";

interface Props {
    dataRepository: DataRepository;
    searchTerm?: string;
    rows: DataRepositoryRow[];
    rowCount?: number;
    pageSize: number;
    setPaginationModel: (paginationModel: { page: number; pageSize: number }) => void;
    page: number;
}
const RepositoryTable: React.FC<Props> = ({
    dataRepository,
    searchTerm,
    rows,
    pageSize,
    setPaginationModel,
    page,
    rowCount,
}) => (
        <DataGridTable
            dataRepository={dataRepository}
            rows={rows}
            pageSize={pageSize}
            setPaginationModel={setPaginationModel}
            page={page}
            searchTerm={searchTerm}
            rowCount={rowCount}
        />
    );

export { RepositoryTable };
