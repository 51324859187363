import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import Search from "@mui/icons-material/Search";
import { Paper, TableContainer, TextField, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import React, { useCallback, useState } from "react";
import { LoadingTable } from "@/components/Loading/LoadingTable";
import { useDataRepositoryContext } from "@/contexts/DataRepositoryContext";
import {
    useGetRepositoryCountFromContext,
    useGetRepositoryDataFromContext,
    useGetRepositoryPagination,
} from "@/contexts/DataRepositoryRowsContext";
import { fm } from "@/contexts/IntlContext";
import messages from "./messages";
import { RepositoryTable } from "./RepositoryTable";
import RepositoryStats from "../RepositoryStats";

const RepositoryTableView: React.FC = () => {
    const { selectedDataRepository } = useDataRepositoryContext();
    const [searchTerm, setSearchTerm] = useState<string>();

    const repositoryData = useGetRepositoryDataFromContext();
    const { paginationData, setPaginationData } = useGetRepositoryPagination();
    const repositoryCount = useGetRepositoryCountFromContext();

    const setPaginationModel = useCallback(
        ({ page, pageSize }: { page: number; pageSize: number }) => {
            if (pageSize !== paginationData.range) {
                // Reset to first page when changing page size
                setPaginationData({ range: pageSize, page: 0 });
                return;
            }
            setPaginationData({ range: pageSize, page });
        },
        [paginationData, setPaginationData]
    );

    const rowCount = repositoryCount.type === "success" ? repositoryCount.data.rowCount : undefined;
    if (selectedDataRepository.fields.length) {
        return (
            <Stack gap={1}>
                <Stack direction="row" justifyContent="space-between" alignItems="end">
                    <TextField
                        size="small"
                        label={fm(messages.search)}
                        placeholder={fm(messages.serachFields).toString()}
                        InputProps={{
                            startAdornment: <Search />,
                        }}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                        }}
                    />
                    <RepositoryStats count={repositoryCount} />
                </Stack>
                <GraphqlRequestContainer
                    asyncData={repositoryData}
                    loading={
                        <TableContainer elevation={0} component={Paper}>
                            <LoadingTable numberOfColumns={15} numberOfRows={25} />
                        </TableContainer>
                    }
                >
                    {(response) => (
                        <RepositoryTable
                            page={paginationData.page}
                            pageSize={paginationData.range}
                            setPaginationModel={setPaginationModel}
                            rowCount={rowCount}
                            searchTerm={searchTerm}
                            dataRepository={selectedDataRepository}
                            rows={response.rows}
                        />
                    )}
                </GraphqlRequestContainer>
            </Stack>
        );
    }
    return (
        <Stack justifyContent="center">
            <Typography>{fm(messages.noData)}</Typography>
        </Stack>
    );
};

export default RepositoryTableView;
