import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import LoadingButton from "@mui/lab/LoadingButton";
import { Autocomplete, DialogContent, FormHelperText, Stack, TextField } from "@mui/material";
import * as Sentry from "@sentry/react";
import React, { useState } from "react";
import { fm } from "@/contexts/IntlContext";
import {
    DataRepository,
    useCreateDataRepositoryCollectionMutation,
    useUpdateDataRepositoryMutation,
} from "@/generated/client";
import globalMessages from "@/messages";
import messages from "./messages";

interface Props {
    repositories: DataRepository[];
    onCreate?: () => void;
}

const CreateDataRepositoryCollectionModal: React.FC<Props> = ({ repositories, onCreate }) => {
    const [createDataRepositoryCollection, mutation] = useCreateDataRepositoryCollectionMutation();
    const [updateDataRepository] = useUpdateDataRepositoryMutation();
    const [name, setName] = useState("");
    const [error, setError] = useState<{ name?: string } | undefined>();
    const [selectedRepositories, setSelectedRepositories] = useState<DataRepository[]>([]);

    const validateString = (input: string) => input.replace(/\s/g, "");

    const handleSubmit = () => {
        const nameStrippedForWhitespace = validateString(name);

        if (!nameStrippedForWhitespace) {
            setError({
                name: fm(messages.required).toString(),
            });
            return;
        }

        createDataRepositoryCollection({ input: { name } })
            .then((response) => {
                selectedRepositories.forEach((rep) => {
                    response !== null &&
                        response.data &&
                        updateDataRepository({
                            input: {
                                id: rep.id,
                                name: rep.name,
                                repositoryCollectionId:
                                    response.data.createDataRepositoryCollection.dataRepositoryCollection.id,
                                autoTriggerPipelines: rep.autoTriggerPipelines,
                            },
                        }).catch((e) => {
                            Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
                        });
                });
                onCreate && onCreate();
            })
            .catch((e) => {
                Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
            });
    };

    return (
        <DialogContent>
            <Stack
                paddingTop={1}
                gap={2}
                sx={{ maxHeight: "80vh", minWidth: "75%" }}
                justifyContent="space-between"
                alignContent="space-between"
            >
                <TextField
                    name="name"
                    label={fm(messages.nameFormInputLabel)}
                    type="text"
                    helperText={error?.name ?? undefined}
                    error={!!error?.name}
                    onChange={(event) => {
                        setName(event.target.value);
                        error && !!validateString(name) && setError(undefined);
                    }}
                />
                <FormHelperText>{fm(messages.nameFormInputHelperText)}</FormHelperText>

                <Autocomplete
                    options={repositories}
                    getOptionLabel={(option) => option.name}
                    multiple
                    value={selectedRepositories}
                    onChange={(_, values) => setSelectedRepositories(values)}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" label={fm(messages.selectRepos)} />
                    )}
                />

                <LoadingButton loading={mutation.result.type === "loading"} type="submit" onClick={handleSubmit}>
                    {fm(globalMessages.submitButton)}
                </LoadingButton>
                <GraphqlRequestContainer loading={null} asyncData={mutation.result} />
            </Stack>
        </DialogContent>
    );
};
export default CreateDataRepositoryCollectionModal;
