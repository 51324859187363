import React, { useState } from "react";
import * as z from "zod";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as Sentry from "@sentry/react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { DataRepository, useCreateRepositoryRowDeleteQueryMutation } from "@/generated/client";
import { fm } from "@/contexts/IntlContext";
import messages from "./messages";

interface Props {
    currentRepository: DataRepository;
    onFinish: () => void;
}

interface FormType {
    value: string;
    dataRepositoryFieldId: string;
}
export const DeleteByValueForm = ({ currentRepository, onFinish }: Props) => {
    const [createAction] = useCreateRepositoryRowDeleteQueryMutation();
    const [loading, setLoading] = useState(false);

    const schema = z.object({
        value: z.string(),
        dataRepositoryFieldId: z.string(),
    });

    const { control, handleSubmit } = useForm<FormType>({
        resolver: zodResolver(schema),
    });
    const onSubmit: SubmitHandler<FormType> = (values) => {
        setLoading(true);
        createAction({
            input: {
                ...values,
                dataRepositoryId: currentRepository.id,
                queryType: "FIELD_VALUE",
            },
        })
            .then(() => {
                setLoading(false);
                onFinish();
            })
            .catch((e) => {
                Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
            });
    };

    return (
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={1}>
                <Controller
                    control={control}
                    name="dataRepositoryFieldId"
                    render={(fieldValue) => {
                        const { field, fieldState } = fieldValue;
                        return (
                            <FormControl>
                                <InputLabel id="dataRepositoryFieldId-label">{fm(messages.dateField)}</InputLabel>
                                <Select
                                    label={fm(messages.dateField)}
                                    {...field}
                                    error={!!fieldState.error}
                                    labelId="dataRepositoryFieldId-label"
                                >
                                    {currentRepository.fields.map((dataRepositoryField) => (
                                        <MenuItem key={dataRepositoryField.id} value={dataRepositoryField.id}>
                                            {dataRepositoryField.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {fieldState.error && (
                                    <FormHelperText color="error">
                                        <Typography color="red">{fieldState.error?.message}</Typography>
                                    </FormHelperText>
                                )}
                            </FormControl>
                        );
                    }}
                />
                <Controller
                    control={control}
                    name="value"
                    render={(fieldValue) => {
                        const { field, fieldState } = fieldValue;
                        return (
                            <TextField
                                label={fm(messages.valueInput)}
                                {...field}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                            />
                        );
                    }}
                />
                <Stack direction="row" justifyContent="flex-end" gap={1}>
                    <Button onClick={onFinish} variant="outlined" color="secondary">
                        {fm(messages.cancel)}
                    </Button>
                    <LoadingButton variant="contained" type="submit" color="success" loading={loading}>
                        {fm(messages.saveQuery)}
                    </LoadingButton>
                </Stack>
            </Stack>
        </form>
    );
};
