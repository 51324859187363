import { openSocketConnection } from "@ignite-analytics/notifications";
import React, { useContext, useEffect, useRef, useMemo } from "react";

export type ValidateFileData = {
    fields: { fieldKey: string; samples: string[] }[];
    warnings: string[];
    errors: string[];
};

type Process = "DataRepositoryDataChange" | "ValidateFile";
type Event = {
    process: Process;
    type: "PROCESS_FINISHED" | "FAILED";
    id?: string;
    data?: Record<string, unknown>;
};
type Handler = (event: Event) => void;
type ContextValue = {
    addListener: (process: Process, handler: Handler) => void;
};
const Context = React.createContext<ContextValue | null>(null);

type Props = {
    children: React.ReactNode;
    userId: string;
    channel: number;
    topic: string;
};
export const DataManagementProcessContextProvider: React.FC<Props> = ({ userId, channel, topic, children }) => {
    const listeners = useRef<Record<Process, Handler[]>>({ DataRepositoryDataChange: [], ValidateFile: [] });
    useEffect(() => openSocketConnection(
            `${process.env.REACT_APP_WS_PROTOCOL || ""}://${
                process.env.REACT_APP_NOTIFICATIONS_URL || ""
            }/ws/v2/${topic}/${localStorage.tenant}/${channel}/${userId}`,
            (message) => {
                const event: Event = JSON.parse(message);
                listeners.current[event.process]?.forEach((fn) => fn(event));
            }
        ), [userId, channel, topic]);

    const value = useMemo(
        () => ({
            addListener: (process: Process, handler: Handler) => {
                listeners.current[process] = listeners.current[process].concat(handler);
                return () => {
                    listeners.current[process] = listeners.current[process].filter((fn) => fn !== handler);
                };
            },
        }),
        []
    );

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useDataManagementProcessEventListener = (process: Process, handler: Handler) => {
    const context = useContext(Context);
    if (!context) {
        throw new Error(
            "Must use useDataManagementProcessEventListener within an DataManagementProcessContextProvider"
        );
    }
    useEffect(() => context.addListener(process, handler), [process, context, handler]);
};
