import { Collapse, Divider, Stack } from "@mui/material";
import React, { useState } from "react";
import { DataRepository, DataRepositoryCollection } from "@/generated/client";
import { CollectionHeader } from "./collectionHeader";
import { RepositoryList } from "./repositoryList";

interface Props {
    collection: DataRepositoryCollection | { name: string };
    repositories: DataRepository[];
    currentRepositoryId: string;
    onClick: (repoId: string) => void;
}

export const RepositoryCollection: React.FC<Props> = ({ collection, repositories, currentRepositoryId, onClick }) => {
    const [open, setOpen] = useState(true);
    return (
        <Stack>
            <CollectionHeader
                open={open}
                collection={collection}
                repositories={repositories}
                onClick={() => setOpen((prev) => !prev)}
            />
            <Divider />
            <Collapse in={open}>
                <RepositoryList
                    collection={collection}
                    repositories={repositories}
                    currentRepositoryId={currentRepositoryId}
                    onClick={onClick}
                />
            </Collapse>
        </Stack>
    );
};
