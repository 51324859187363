import { Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { fm } from "@/contexts/IntlContext";
import { DataRepository, useUpdateDataRepositoryMutation } from "@/generated/client";
import messages from "@/messages";

export const RenameDataRepository: React.FC<{ repository: DataRepository; onUpdate: () => void }> = ({
    repository,
    onUpdate,
}) => {
    const [updatedName, setUpdatedName] = useState<string>(repository.name);
    const [updateDataRepository, mutation] = useUpdateDataRepositoryMutation({
        refetchQueries: ["getAllDataRepositories"],
    });

    const handleNameChange = () => {
        if (!updatedName.length || updatedName === repository.name) return;
        updateDataRepository({
            input: {
                id: repository.id,
                name: updatedName,
                repositoryCollectionId: repository.repositoryCollectionId,
                autoTriggerPipelines: repository.autoTriggerPipelines,
            },
        }).catch((e) => {
            Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
        });
        onUpdate();
    };

    useEffect(() => {
        setUpdatedName(repository.name);
    }, [repository]);

    return (
        <Stack direction="row" gap={1}>
            <TextField name="name" value={updatedName} size="small" onChange={(e) => setUpdatedName(e.target.value)} />
            <LoadingButton
                loading={mutation.result.type === "loading"}
                disabled={updatedName === repository.name}
                onClick={handleNameChange}
                color="success"
            >
                {fm(messages.save)}
            </LoadingButton>
        </Stack>
    );
};
