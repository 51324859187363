import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import { Cancel } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton, Skeleton, Snackbar, Stack } from "@mui/material";
import React, { useState } from "react";
import { CreateUniqueIdentifierConfigurationForm, MutationName } from "@/components/UniqueIdentifierConfigurationForm";

import { fm } from "@/contexts/IntlContext";
import { DataRepository, useGetDataRepositoryQuery } from "@/generated/client";
import messages from "./messages";

interface Props {
    repository: DataRepository;
    open: boolean;
    onClose: () => void;
}

export const ManageDuplicatesModal = ({ repository, open, onClose }: Props) => {
    const { result, refetch } = useGetDataRepositoryQuery({ input: { id: repository.id } });
    const [showNotification, setShowNotification] = useState<MutationName | null>(null);
    return (
        <>
            <Dialog fullWidth open={open} onClose={onClose}>
                <DialogTitle>{fm(messages.configFields)}</DialogTitle>
                <DialogContent>
                    <GraphqlRequestContainer
                        asyncData={result}
                        loading={
                            <Stack>
                                <Skeleton variant="rectangular" width={150} height={40} />
                                <Skeleton variant="rectangular" width={210} height={40} />
                                <Skeleton variant="rectangular" width={210} height={80} />
                            </Stack>
                        }
                    >
                        {(response) => {
                            const config = response.dataRepository.uniqueIdentifierConfiguration;
                            return (
                                <CreateUniqueIdentifierConfigurationForm
                                    repository={repository}
                                    currentConfig={config}
                                    onMutation={(mutationName: MutationName) => {
                                        refetch();
                                        setShowNotification(mutationName);
                                    }}
                                    onCancel={onClose}
                                />
                            );
                        }}
                    </GraphqlRequestContainer>
                </DialogContent>
            </Dialog>
            <Snackbar
                open={showNotification !== null}
                onClose={() => {
                    setShowNotification(null);
                }}
                action={
                    <IconButton
                        onClick={() => {
                            setShowNotification(null);
                        }}
                    >
                        <Cancel />
                    </IconButton>
                }
                autoHideDuration={5000}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                message={fm(showNotification === "delete" ? messages.deleteMessage : messages.createOrUpdateMessage)}
            />
        </>
    );
};
